import { Columnz, DataTablezD } from "@/components/data_table";
import { useGetParams } from "@/hooks";
import { useState } from "react";
import {
  Calendarz,
  GridForm,
  HeaderListForm,
} from "@/components/data_table/FormList";
import { useListLeadperDay, useListPbReport } from "../utils";
import { expListLeadperDay } from "../api";
const ReportDay = () => {
  const initParam = useGetParams();
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1, 7, 0, 0);
  const lastDay = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
    7,
    0,
    0
  );
  const formattedFirstDay = new Date(firstDay)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  const formattedLastDay = new Date(lastDay)
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  const [params, setParams] = useState({
    ...initParam,
    from: formattedFirstDay,
    to: formattedLastDay,
  });
  const [filter, setFilter] = useState({
    ...params,
    dates: [firstDay, lastDay],
  });
  const list_pb_report = useListPbReport();
  const list_data = useListLeadperDay({
    ...params,
    from: params?.from,
    to: params?.to,
  });
  return (
    <div className="card">
      <HeaderListForm title="Báo cáo theo Ngày" />
      <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
        <Calendarz
          className="mt-2 col-4"
          value={filter?.dates}
          onChange={(e) => setFilter({ ...filter, dates: e.value })}
          placeholder={"Chọn khoảng thời gian"}
          selectionMode="range"
        />
        <div className="col-5"></div>
      </GridForm>
      <div className="flex flex-column">
        <div className="col-12 mx-auto flex">
          <DataTablezD
            style={{ width: "100%" }}
            title="báo cáo theo ngày"
            value={list_data?.sort(
              (a, b) =>
                new Date(b.Day.split("/").reverse().join("-")) -
                new Date(a.Day.split("/").reverse().join("-"))
            )}
            params={params}
            totalRecords={list_data?.length == 0 ? 0 : list_data?.length}
            setParams={setParams}
            numRows={list_data?.length}
            basePermissions={["export"]}
            noCheckTime
            headerInfo={{
              exportApi: () => expListLeadperDay(params),
            }}
          >
            <Columnz field="Day" header="Ngày" />
            {list_pb_report?.map((e) => (
              <Columnz field={e?.gb_title} header={e?.gb_title} />
            ))}
            <Columnz field="Không phòng ban" header="Không phòng ban" />
            <Columnz field="total" header="Tổng" />
          </DataTablezD>
        </div>
      </div>
    </div>
  );
};
export default ReportDay;
