import { useState } from "react";
import {
  useCountListFormbyPage,
  useListAllProject,
  useListFormbyPage,
  useListProjectForm,
} from "../utils";
import { useGetParams } from "../../../hooks/useGetParams";
import { Columnz, DataTablez } from "@/components/data_table";
import { HeaderListForm } from "@/components/data_table/FormList";
import { LoadDialog } from "@/components/data_table/Dialogz";
import { Button } from "@/uiCore";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import { updateProjectCampaign, updateProjectForm } from "../api";
const PageForm = () => {
  const initParam = useGetParams();
  const [params, setParams] = useState(initParam);
  const [visible, setVisible] = useState(false);
  const [infos, setInfos] = useState();
  const locaion = useLocation().pathname;
  const navigate = useNavigate();
  const page_id = locaion?.split("/")?.[3];
  const list_page = useListFormbyPage({
    status: undefined,
    ...params,
    first: undefined,
    project_id_ad: page_id,
  });
  const list_project = useListAllProject();
  const totalRecords =
    useCountListFormbyPage({
      // status: undefined,
      // ...params,
      // first: undefined,
      project_id_ad: page_id,
    }) || 0;
  const [showDialog, setShowDialog] = useState(false);
  const [campaignName, setCampaignName] = useState();
  const list_projectForm = useListProjectForm();
  const handleData = () => {
    if (!infos?.category_id) return "Bạn chưa chọn dự án";
    return infos;
  };
  const handleShow = (page, id, category_id) => {
    setShowDialog(true);
    setCampaignName(page);
    setInfos({ ...infos, category_id: category_id, id: id });
  };
  const handleReturn = () => {
    navigate("/page_list");
  };
  const getProject = (id) => {
    return list_project?.filter((e) => e.cb_id == id)?.[0];
  };
  return (
    <div>
      {showDialog && (
        <FormUpdateDialog
          visible={showDialog}
          setVisible={setShowDialog}
          title="Phân chia dự án theo form"
          checkId={Number(showDialog)}
          setParams={setParams}
          actions={{ update: updateProjectForm }}
          handleData={handleData}
        >
          <div className="grid grid-form" style={{ marginBottom: "20vh" }}>
            <div className="col-6">
              <InputForm label="Tên form" value={campaignName} readOnly />
            </div>
            <div className="col-6 ">
              <DropdownForm
                value={infos?.category_id}
                onChange={(e) =>
                  setInfos({ ...infos, category_id: e.target.value })
                }
                options={list_projectForm}
                optionLabel={(option) =>
                  `${option.cb_title}  (${option.cb_code})`
                }
                optionValue="cb_id"
                label="Dự án"
              />
            </div>
          </div>
        </FormUpdateDialog>
      )}
      <div className="card mx-auto ">
        <HeaderListForm title="Danh sách form " />
        <LoadDialog visible={visible} />
        <DataTablez
          title="phê duyệt"
          value={list_page}
          totalRecords={totalRecords}
          params={params}
          setParams={setParams}
        >
          <Columnz header="Id Form" field="id_form" />
          <Columnz header="Tên Form" field="name" />
          <Columnz
            header="Dự án"
            body={(e) => (
              <div style={{ display: "flex" }}>
                <span className="mr-4">
                  {getProject(e.project_id)
                    ? getProject(e.project_id)?.cb_title
                    : "Chưa có dự án"}
                </span>
                <i
                  className="pi pi-file-edit"
                  style={{ fontSize: "1.4rem", color: "#14b8a6" }}
                  onClick={() => handleShow(e?.name, e.id, e.project_id)}
                ></i>
              </div>
            )}
          />
        </DataTablez>
        <div className="flex flex-row-reverse mt-3">
          <Button severity="danger" onClick={() => handleReturn()}>
            Quay về
          </Button>
        </div>
      </div>
    </div>
  );
};
export default PageForm;
