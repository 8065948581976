import { useEffect, useState } from "react";
import {
  useCountListPage,
  useGetListDxc,
  useGetListFormZalo,
  useGetListLeadZaloForm2,
  useListAllProject,
  useListPage,
  useListProjectForm,
} from "../utils";
import { useGetParams } from "../../../hooks/useGetParams";
import { Columnz, DataTablez, StatusBody } from "@/components/data_table";
import {
  Calendarz,
  Dropdownz,
  GridForm,
  HeaderListForm,
  Inputz,
} from "@/components/data_table/FormList";
import { LoadDialog } from "@/components/data_table/Dialogz";
import { Button, Image } from "@/uiCore";
import { Link, useLocation } from "react-router-dom";
import {
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import {
  autoZalo,
  refreshLead,
  updateActivePage,
  updateActiveZaloForm,
  updateCookie,
  updateDxcHandle,
  updateFormZalo,
  updatePageList,
  updateProjectCampaign,
  updateProjectZalo,
} from "../api";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useDispatch } from "react-redux";
import { listToast, status } from "@/constants";
import { setToast } from "@/redux/features";
import { clientId } from "@/axios/clientApi";
import { red } from "@mui/material/colors";
const Header = ({ setParams, setFilter, filter, params }) => {
  const list_projectForm = useListProjectForm();

  return (
    <GridForm setParams={setParams} filter={filter} setFilter={setFilter}>
      <Inputz
        value={filter?.key_search}
        className="col-5"
        placeholder="Tìm kiếm theo tên page"
        onChange={(e) => setFilter({ ...filter, key_search: e.target.value })}
      ></Inputz>
      <Dropdownz
        value={filter?.category_id}
        onChange={(e) => setFilter({ ...filter, category_id: e.target.value })}
        options={list_projectForm}
        className=" col-6"
        placeholder="Chọn dự án"
        optionLabel={(option) => `${option.cb_title}  (${option.cb_code})`}
        optionValue="cb_id"
        filter
        style={{ lineHeight: "30px" }}
      ></Dropdownz>
      <Dropdownz
        value={filter?.status}
        onChange={(e) => setFilter({ ...filter, status: e.target.value })}
        options={[
          { id: 1, name: "ACTIVE" },
          { id: 0, name: "INACTIVE" },
        ]}
        className=" col-3"
        placeholder="Chọn trạng thái"
        style={{ lineHeight: "30px" }}
      ></Dropdownz>

      <div className="col-9"></div>
    </GridForm>
  );
};
const ZaloList = () => {
  const initParam = useGetParams();
  const [filter, setFilter] = useState();
  const [params, setParams] = useState(initParam);
  const [showConfirm, setShowConfirm] = useState(false);
  const [pageChosen, setPageChosen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [infos, setInfos] = useState();
  const list_project = useListAllProject();
  const listZalo = useGetListFormZalo({
    status: undefined,
    ...params,
    first: undefined,
  });
  const getProject = (id) => {
    return list_project?.filter((e) => e.cb_id == id)?.[0];
  };
  const [showDialog, setShowDialog] = useState(false);
  const [formName, setFormName] = useState();
  const handleShow = (form, id, category_id) => {
    setShowDialog(true);
    setFormName(form);
    setInfos({ ...infos, category_id: category_id, id: id });
  };
  const list_projectForm = useListProjectForm();
  const handleData = () => {
    if (!infos?.category_id) return "Bạn chưa chọn dự án";
    return infos;
  };
  const updateActive = async (id) => {
    await updateActivePage({ id: id });
  };
  const setOnHide = () => {
    setShowConfirm(false);
    setPageChosen({});
  };
  // const handleZalo = async () => {
  //   await autoZalo();
  // };
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const dispatch = useDispatch();
  const handleUpdate = async () => {
    setLoading(true);
    const res = await updateFormZalo();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (res?.mess == "Không tìm thấy dữ liệu") {
      dispatch(
        setToast({
          ...listToast[1],
          detail: `${res?.mess}`,
        })
      );
      if (params && setParams) {
        setParams((pre) => {
          return { ...pre, render: !pre.render };
        });
      }
    } else {
      dispatch(
        setToast({
          ...listToast[0],
          detail: `Cập nhật dữ liệu Zalo thành công!`,
        })
      );
      if (params && setParams) {
        setParams((pre) => {
          return { ...pre, render: !pre.render };
        });
      }
    }
  };
  const convert_Time = (string) => {
    const date = String(string)?.split(" ")?.[0]?.split("-");
    const time = String(string)?.split(" ")?.[1]?.split(":");
    return `${time?.[0]}:${time?.[1]} ngày ${date?.[2]} / ${date?.[1]} / ${date?.[0]}`;
  };
  const dxc = useGetListDxc({ ...params });
  const handleZalo = async () => {
    setLoading1(true);
    const res = await updateCookie();
    if (res?.data?.zalo_expires) {
      setTimeout(() => {
        setLoading1(false);
      }, 1000);
      dispatch(
        setToast({
          ...listToast[0],
          detail: `Cập nhật cookie thành công`,
        })
      );
      if (params && setParams) {
        setParams((pre) => {
          return { ...pre, render: !pre.render };
        });
      }
    }
    if (res?.data?.error) {
      setTimeout(() => {
        setLoading1(false);
      }, 1000);
      if (
        String(res?.data?.error?.stack)?.includes("ECONNREFUSED 127.0.0.1:9222")
      ) {
        dispatch(
          setToast({
            ...listToast[1],
            detail:
              "Không thể kết nối 127.0.0.1:9222. Đảm bảo bạn đang chạy Chrome debug",
          })
        );
      } else {
        dispatch(
          setToast({ ...listToast[1], detail: "Lỗi khi thực hiện hành động" })
        );
      }
    }
  };
  const expiresDate = () => {
    if (!dxc?.[0]?.zalo_expires) {
      return <strong style={{ color: "gray" }}>Không có dữ liệu</strong>;
    }

    const expirationDate = new Date(dxc[0].zalo_expires);
    const today = new Date();

    if (expirationDate < today) {
      return (
        <strong style={{ color: "red" }}>
          Đã hết hạn ({dxc[0].zalo_expires})
        </strong>
      );
    }

    return (
      <strong>Còn hạn đến {convert_Time(String(dxc[0].zalo_expires))}</strong>
    );
  };
  return (
    <div>
      <ConfirmDialog
        header="Hello"
        visible={showConfirm}
        onHide={() => setOnHide()}
        accept={() => updateActive(pageChosen?.id)}
        position="center"
      ></ConfirmDialog>
      {showDialog && (
        <FormUpdateDialog
          visible={showDialog}
          setVisible={setShowDialog}
          title="Phân chia dự án"
          checkId={Number(showDialog)}
          setParams={setParams}
          actions={{ update: updateProjectZalo }}
          handleData={handleData}
        >
          <div className="grid grid-form" style={{ marginBottom: "20vh" }}>
            <div className="col-6">
              <InputForm label="Form" value={formName} readOnly />
            </div>
            <div className="col-6 ">
              <DropdownForm
                value={infos?.category_id}
                onChange={(e) =>
                  setInfos({ ...infos, category_id: e.target.value })
                }
                options={list_projectForm}
                optionLabel={(option) =>
                  `${option.cb_title}  (${option.cb_code})`
                }
                optionValue="cb_id"
                label="Dự án"
              />
            </div>
          </div>
        </FormUpdateDialog>
      )}
      <div className="card mx-auto ">
        <HeaderListForm title="Quản lý Zalo Form " />
        <div
          className="flex flex-row gap-3 mb-3"
          style={{ justifyContent: "cen", alignItems: "center" }}
        >
          <Button onClick={() => handleUpdate()} loading={loading}>
            Cập nhật Zalo Form
          </Button>
          <Button severity="info" onClick={handleZalo} loading={loading1}>
            Cập nhật cookie
          </Button>
          <span className="font-medium text-3xl">
            Zalo Cookie : {expiresDate()}
          </span>
        </div>
        {/* <Button
          onClick={() => handleUpdateToken()}
          loading={loading}
          className="mb-3"
        >
          Cập nhật lại
        </Button> */}
        <Header
          setParams={setParams}
          setFilter={setFilter}
          filter={filter}
          params={params}
        />

        <LoadDialog visible={visible} />
        <DataTablez
          title="phê duyệt"
          value={listZalo}
          totalRecords={listZalo?.length}
          params={params}
          setParams={setParams}
        >
          <Columnz header="Form" field="form_name" />
          <Columnz header="ID Form" field="form_id" />
          <Columnz
            header="Hình ảnh"
            body={(e) => (
              <div style={{ display: "flex" }}>
                <Image src={e?.cover_photo} width="150" preview />
              </div>
            )}
          />
          <Columnz
            header="Dự án"
            body={(e) => (
              <div style={{ display: "flex" }}>
                <span className="mr-4">
                  {getProject(e.category_id)
                    ? getProject(e.category_id)?.cb_title
                    : "Chưa có dự án"}
                </span>
                <i
                  className="pi pi-file-edit"
                  style={{ fontSize: "1.4rem", color: "#14b8a6" }}
                  onClick={() => handleShow(e?.form_name, e.id, e.category_id)}
                ></i>
              </div>
            )}
          />
          <Columnz
            body={(e) => (
              <Link to={`/page_list/page_detail/${e?.page_id}`}>
                {" "}
                <Button
                  type="button"
                  icon="pi pi-eye"
                  rounded
                  outlined
                  className={"mr-1 mx-auto flex"}
                />
              </Link>
            )}
            header="Danh sách chiến dịch"
          />
          <Columnz
            header="Active"
            body={(e) =>
              StatusBody({
                e,
                label: "ZaloForm",
                isAllow: e?.category_id ? true : false,
                updateAction: updateActiveZaloForm,
                setParams: setParams,
                params: params,
              })
            }
          />
        </DataTablez>
      </div>
    </div>
  );
};
export default ZaloList;
