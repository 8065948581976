import React, { useState, useEffect, useRef } from "react";
import { Chart } from "primereact/chart";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const getMax = (b) => {
  let max = b[0];
  for (let i = 1; i < b.length; i++) {
    if (b[i] > max) {
      max = b[i];
    }
  }
  return max;
};
export const StackedBarPerDay = (props) => {
  const { data_day, data_list_day, list_pb, label } = props;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef(null);
  const colors = [
    "rgba(255, 99, 132, 2)",
    "rgba(54, 162, 235, 2)",
    "rgba(255, 206, 86, 2)",
    "rgba(75, 192, 192, 2)",
    "rgba(153, 102, 255, 2)",
    "rgba(255, 159, 64, 2)",
    "rgba(142, 68, 173, 2)",
    "rgba(39, 174, 96, 2)",
    "rgba(230, 126, 34, 2)",
    "rgba(211, 84, 0, 2)",
  ];
  const a = [];
  for (let i = 0; i < data_day.length; i++) {
    a.push(data_day?.[i].value);
  }
  const b = [];
  for (let j = 0; j < a[0]?.length; j++) {
    let sum = 0;
    for (let i = 0; i < a.length; i++) {
      sum += a[i][j];
    }
    b.push(sum);
  }

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: list_pb,
      datasets: data_day
        ?.sort(
          (a, b) =>
            new Date(a.day.split("/").reverse().join("-")) -
            new Date(b.day.split("/").reverse().join("-"))
        )
        ?.map((e, index) => ({
          type: "bar",
          label: e?.day,
          backgroundColor: colors[index],
          data: e.value,
          barThickness: data_day?.length < 10 ? 50 : 20,
        })),
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,

      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
          position: "bottom",
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
            stepSize: getMax(b) < 10 ? 1 : 10,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [data_day, list_pb]);
  const exportPDF = async () => {
    const chartContainer = chartRef.current;
    const canvas = await html2canvas(chartContainer, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("landscape", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 10, 10, pdfWidth - 20, pdfHeight);
    pdf.save(`${label || "chart"}.pdf`);
  };
  return (
    <div className="card">
      <div className="flex flex-row justify-content-between align-items-center">
        <span className="font-bold text-xl ml-4">{label}</span>
        <button
          onClick={exportPDF}
          className="p-3 bg-blue-500 font-semibold text-white rounded shadow-4 hover:bg-blue-600 cursor-pointer"
          style={{ borderRadius: "10px", border: "none" }}
        >
          Export to PDF
        </button>
      </div>
      <div ref={chartRef} className="mt-3">
        <Chart type="bar" data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};
export const StackedBarPerProject = (props) => {
  const { data_day, list_project, label } = props;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const chartRef = useRef(null);
  const colors = [
    "rgba(255, 99, 132, 2)",
    "rgba(54, 162, 235, 2)",
    "rgba(255, 206, 86, 2)",
    "rgba(75, 192, 192, 2)",
    "rgba(153, 102, 255, 2)",
    "rgba(255, 159, 64, 2)",
    "rgba(142, 68, 173, 2)",
    "rgba(39, 174, 96, 2)",
    "rgba(230, 126, 34, 2)",
    "rgba(211, 84, 0, 2)",
  ];
  let list = [];
  for (let i = 0; i < list_project.length; i++) {
    list.push(Number(list_project[i]?.total));
  }

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: list_project?.map((e) => e?.project),
      datasets: data_day?.map((e, index) => ({
        type: "bar",
        label: e?.pb,
        backgroundColor: colors[index],
        data: e.value,
        barThickness: data_day?.length < 10 ? 50 : 20,
      })),
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        tooltips: {
          mode: "index",
          intersect: false,
        },
        legend: {
          labels: {
            color: textColor,
          },
          position: "bottom",
        },
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary,
            stepSize: getMax(list) < 10 ? 1 : 10,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [data_day, list_project]);
  const exportPDF = async () => {
    const chartContainer = chartRef.current;
    const canvas = await html2canvas(chartContainer, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("landscape", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    pdf.addImage(imgData, "PNG", 10, 10, pdfWidth - 20, pdfHeight);
    pdf.save(`${label || "chart"}.pdf`);
  };
  return (
    <div className="card">
      <div className="flex flex-row justify-content-between align-items-center">
        <span className="font-bold text-xl ml-4">{label}</span>
        <button
          onClick={exportPDF}
          className="p-3 bg-blue-500 font-semibold text-white rounded shadow-4 hover:bg-blue-600 cursor-pointer"
          style={{ borderRadius: "10px", border: "none" }}
        >
          Export to PDF
        </button>
      </div>
      <div ref={chartRef} className="mt-3">
        <Chart type="bar" data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};
export const FinalChart = (props) => {
  const { data, title, color } = props;
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  // const dataLine = data;
  const dataLine = [
    { date: "2024-01-01", sum: 100 },
    { date: "2024-01-02", sum: 150 },
    { date: "2024-01-03", sum: 130 },
    { date: "2024-01-04", sum: 170 },
    { date: "2024-01-05", sum: 160 },
    { date: "2024-01-06", sum: 180 },
    { date: "2024-01-07", sum: 200 },
  ];
  const labelLine = dataLine.map((d) => d.date);
  const lineData = dataLine.map((d) => d.sum);

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: labelLine,
      datasets: [
        {
          data: lineData,
          fill: true,
          borderColor: documentStyle.getPropertyValue("--blue-500"),
          tension: 0,
          backgroundColor: createGradient(color),
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.9,

      plugins: {
        legend: {
          display: false,
        },
      },
    };
    setChartOptions(options);
    setChartData(data);
  }, [data]);

  // Function to create gradient
  const createGradient = (color) => {
    const gradient = document.createElement("canvas").getContext("2d");
    gradient.canvas.width = 100;
    gradient.canvas.height = 100;
    const gradientFill = gradient.createLinearGradient(0, 0, 0, 320);
    gradientFill.addColorStop(0, color);
    gradientFill.addColorStop(1, "#ffffff");
    return gradientFill;
  };

  return (
    <div className="card">
      <span className="font-bold text-xl ml-4">{title}</span>
      <div className="mt-3">
        <Chart type="line" data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};
