import {
  useListLeadperDay,
  useListPBLeadperDay,
  useListPbReport,
  useListProject,
  useStaticLeadperDay,
  useStaticLeadperPb,
} from "@/modules/report/utils";
import { FinalChart, StackedBarPerDay, StackedBarPerProject } from "./chart";
import cus1 from "@/image/cus1.png";
import cus2 from "@/image/cus2.png";
import cus3 from "@/image/cus3.png";
import cus4 from "@/image/cus4.png";
import cus5 from "@/image/cus5.png";
import cus6 from "@/image/cus6.png";
import cus7 from "@/image/cus7.png";
import {
  useGetListSheet,
  useListAssignCategory,
  useListCampaign,
  useListLead,
  useListPage,
} from "@/modules/project/utils";
import { CardReport } from "@/components/data_table/Cardz";
const today7 = () => {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

  const formatDate = (date, today) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    if (today == "1") {
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return `${year}-${month}-${day} 00:00:00`;
  };

  return {
    sevenDaysAgo: formatDate(sevenDaysAgo, "0"),
    today: formatDate(today, "1"),
  };
};
const Home = () => {
  const list_data = useStaticLeadperDay();
  const day_data = useListLeadperDay();
  const list_pb = useListPBLeadperDay();
  const list_data_project = useStaticLeadperPb();
  const list_project = useListProject();
  const list_page = useListPage();
  const list_campaign = useListCampaign();
  const list_pb_report = useListPbReport();
  const list_project_assign = useListAssignCategory();
  const list_lead = useListLead();
  const day = today7();
  const list_lead_7 = useListLead({ from: day?.sevenDaysAgo, to: day?.today });
  const list_sheet = useGetListSheet({});
  return (
    <div className="card grid">
      {/* <div className="col-6">
        <FinalChart title="Phải thu cuối kỳ" color="#56a2f8" />
      </div>
      <div className="col-6"></div> */}
      <div className="col-6">
        <StackedBarPerDay
          data_day={list_data}
          data_list_day={day_data}
          list_pb={list_pb}
          label={"Thống kê data theo ngày (7 ngày qua)"}
        />
      </div>
      <div className="col-6">
        <StackedBarPerProject
          label={"Thống kê data theo dự án (7 ngày qua)"}
          data_day={list_data_project}
          list_project={list_project}
        />
      </div>
      <div className="col-12 flex flex-row">
        <CardReport
          src={cus1}
          num={list_lead?.length}
          title={"Khách hàng"}
          label={"Quản lý khách hàng"}
          redirect={"/divide"}
        />
        <CardReport
          src={cus2}
          num={list_lead_7?.length}
          title={"Khách hàng 7 ngày qua"}
          label={"Quản lý khách hàng"}
          redirect={"/divide"}
        />
        <CardReport
          src={cus3}
          num={list_pb_report?.length}
          title={"Sàn tiếp nhận khách hàng"}
          label={"Phân bổ dự án"}
          redirect={"/project_list"}
        />
      </div>
      <div className="col-12 flex flex-row">
        <CardReport
          src={cus5}
          num={list_page?.length}
          title={"Page Facebook"}
          label={"Quản lý Page"}
          redirect={"/page_list"}
        />
        <CardReport
          src={cus6}
          num={list_campaign?.length}
          title={"Chiến dịch/form hoạt động"}
          label={"Danh sách Page - Chiến dịch"}
          redirect={"/page_list"}
        />
        <CardReport
          src={cus7}
          num={list_sheet?.filter((e) => e?.status == 1)?.length}
          title={"Form GoogleSheet đang hoạt động"}
          label={"Quản lý Google Sheet"}
          redirect={"/sheet_list"}
        />
      </div>
    </div>
  );
};
export default Home;
