import { Button } from "primereact/button";
import error from "@/image/1_847Jp_9BIkm95T42f1Wczw-Photoroom.png";
const ErrorPath = () => {
  const handleReturn = () => {
    window.history.back();
  };
  return (
    // <div class="flex align-items-center justify-content-center">
    //   <div className="flex flex-column">
    //     <span className="fadein animation-duration-1000 animation-iteration-infinite font-bold text-red-500 text-8xl		">
    //       URL hiện không tồn tại
    //     </span>
    //     <Button
    //       onClick={handleReturn}
    //       className="font-bold w-8 text-4xl mx-auto"
    //     >
    //       Trở về trang trước đó
    //     </Button>
    //   </div>
    // </div>

    <div
      className="fadein animation-duration-1000 h-screen flex align-items-center justify-content-center"
      // style={{ backgroundColor: "#caeceb", position: "relative" }}
    >
      {/* <Button
        onClick={handleReturn}
        severity="info"
        className="font-bold  text-3xl mx-auto"
        style={{ position: "absolute", top: "0", left: "0" }}
      >
        Trở về trang trước đó
      </Button> */}
      <img src={error} style={{}}></img>
      <div className="flex flex-column">
        <span className="font-bold text-7xl my-3">404 ERROR</span>
        <span className="font-medium text-3xl my-4">
          "ToolMarketing có thể làm mọi thứ cho bạn,<br></br> nhưng không thể
          hiển thị một trang web <br></br>mà bản thân nó chưa từng xuất hiện"
        </span>
        <button onClick={handleReturn} class="button-19" role="button">
          Trở về trang trước đó
        </button>
      </div>
    </div>
  );
};
export default ErrorPath;
