import { useEffect, useState } from "react";
import {
  detailProject,
  getListDepartment,
  getListLead,
  getListAllProject,
  getListAllPage,
  getListAllForm,
  getListProjectForm,
  getListFormPage,
  getListAssignDate,
  listCampaign,
  getCheckId,
  getPbIdByDate,
  getListPage,
  getListFormbyPage,
  getListAssignCategory,
  getListAssignPage,
  getCategoryDetail,
  getListLeadbYCategory,
  listPrioritize,
  listSource,
  getListSheet,
  getListDetailSheet,
  getListAssignSheet,
  listPbTour,
  getListAssignCategorySearch,
  listAssignCategorySearch,
  getUserLogToken,
  getListTeambyProject,
  getListDxc,
  getListFormZalo,
  getListLeadZaloForm2,
} from "../api";
import axios from "axios";

export const useListLead = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListLead({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListLead = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListLead({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListDepartment = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListDepartment({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useDetailProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await detailProject({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAllProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllProject({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAllPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useCountListAllPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAllForm = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAllForm({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListProjectForm = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListProjectForm({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListFormPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListFormPage({ status: 1, ...params });
    if (response?.status) setData(response?.data?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignDate = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignDate({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListCampaign = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listCampaign({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetCheckId = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getCheckId({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetPbIdByDate = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getPbIdByDate({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useListPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListFormbyPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListFormbyPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListFormbyPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListFormbyPage({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useListAssignCategory = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignCategory({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignCategorySearch = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listAssignCategorySearch({
      status: 1,
      ...params,
    });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCountListAssignCategory = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignCategory({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListAssignPage = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListAssignPage({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useCategoryDetail = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getCategoryDetail({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListLeadbYCategory = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListLeadbYCategory({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListPrioritize = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listPrioritize({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useListSource = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listSource({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useCountListSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListDetailSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListDetailSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};

export const useCountListDetailSheet = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListDetailSheet({ status: 1, ...params });
    if (response?.status) setData(response?.data.total);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListPbTour = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await listPbTour({ status: 1, ...params });
    if (response?.status) setData(response?.data.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetUserLogToken = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getUserLogToken({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListTeambyProject = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListTeambyProject({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListDxc = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListDxc({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListFormZalo = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListFormZalo({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListLeadZaloForm2 = (params) => {
  const [data, setData] = useState([]);
  async function fetchData() {
    const response = await getListLeadZaloForm2({ status: 1, ...params });
    if (response?.status) setData(response?.data);
  }
  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);
  return data;
};
export const useGetListLeadZalo = (params) => {
  const cookies =
    "_ga_NVN38N77J3=GS1.2.1720411100.1.1.1720411872.0.0.0; _ga_1J0YGQPT22=GS1.1.1725009433.1.0.1725009436.57.0.0; zpsid=h3gy.239683633.36.w43yVJ3bRQy1hIIXFELjhqMM7vq3t5kN0zDUd3vKZaKIZZyFCLFNJLRbRQy; __zi=3000.SSZzejyD6zOgdh2mtnLQWYQN_RAG01ICFjIXe9fEM8uzaEkgca5TXNANxwZNGbk5UfJdhpSp.1; __zi-legacy=3000.SSZzejyD6zOgdh2mtnLQWYQN_RAG01ICFjIXe9fEM8uzaEkgca5TXNANxwZNGbk5UfJdhpSp.1; ads_sid=aVgVQVn3OrM9y9zc-KX-K8pjnc7y7rykZFR2UhPCPJQyd-nsitvB0hsuit6F00X1fyNtFg0RN0YLsybidHbSCwVKq7gY4LKpi_Z8PubGPWQvXDfgP0; _gcl_au=1.1.868177098.1727852326; _fbp=fb.1.1727852326730.790635102932993652; _ga_E63JS7SPBL=GS1.1.1727859655.4.0.1727859663.52.0.0; _ga_RYD7END4JE=GS1.2.1729484182.1.1.1729484182.60.0.0; zoaw_sek=jgRp.121775887.1.xbeGFCZdbQt7mDUgoEULhSZdbQt8vvMjoNrAdw_dbQq; zoaw_type=0; _hjSessionUser_3705269=eyJpZCI6IjFiMTI5YzRhLTk5NjgtNWM2MS1hMjNmLWNmNDRmMjgyZGIyYyIsImNyZWF0ZWQiOjE3MzEzOTk1OTgyMTEsImV4aXN0aW5nIjp0cnVlfQ==; _ga_QKW3RCKZFH=GS1.1.1731637473.2.1.1731637553.0.0.0; _ga_GP4XVYJ45L=GS1.1.1731647641.2.1.1731647650.51.0.0; __utmz=112036180.1731664571.18.13.utmcsr=google|utmccn=(organic)|utmcmd=organic|utmctr=(not%20provided); _gid=GA1.2.163829133.1732073144; _zlang=vn; googtrans=/auto/vi; __utma=112036180.543213506.1711211660.1731664571.1732077780.19; __utmc=112036180; _ga_4QSSY47VGF=GS1.1.1732077780.18.1.1732077793.47.0.0; _ga_C51H66N22P=GS1.2.1732077789.13.1.1732077793.56.0.0; _ga_S4Y4BPVHZV=GS1.1.1732077780.18.1.1732077800.0.0.0; _ga=GA1.2.543213506.1711211660; _ga_907M127EPP=GS1.1.1732076479.27.1.1732078460.57.0.0; zf_id=eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqNHV2d2s3OTRnIiwiZXhwIjoxNzMyMTcxNDgyfQ.vkxdWrEIAoneQMG3xBUMmC_OgamCSOuUmpRlQE8JE-0";

  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "/api/client/manage/api/manageForm/getFormLeads?oaId=AEUZEPYJGGDGPANMB9&formIds=vvun6wgj4k_qbu9zg84epyrykydkv&status=0%2C1%2C2%2C4%2C3%2C5&keyword=&fromTime=1729581173&toTime=1732121999&offset=0&limit=10",
        {
          headers: {
            "Content-Type": "application/json",
            Cookie: cookies,
          },
          withCredentials: true,
        }
      );
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);

  return data;
};
