import {
  CalendarForm,
  DropdownForm,
  FormUpdateDialog,
  InputForm,
} from "@/components/data_table/FormUpdate";
import { useState } from "react";
import { addLeadHand, addLeadMiss } from "../api";
import { useListDepartment, useListPage, useListProjectForm } from "../utils";
import { Button, Password } from "@/uiCore";
import { FloatLabel } from "primereact/floatlabel";

const AddMiss = (props) => {
  const { leadVisible, setLeadVisible, setParams, list_source } = props;
  const [infos, setInfos] = useState({});
  const [pass, setPass] = useState();
  const [disabled, setDisabled] = useState(true);
  const handleData = () => {
    const info = { ...infos };
    if (!info?.project_id_ad) return "Bạn chưa chọn page";
    if (!info?.date) return "Bạn chưa chọn thời gian";
    return info;
  };
  const list_page = useListPage({ status: undefined });
  const handleCheck = () => {
    if (pass == "duc123456789") {
      setDisabled(false);
    }
  };
  return (
    <>
      <FormUpdateDialog
        title="THÊM KHÁCH HÀNG MISS ( ONLY SUPER ADMIN)"
        visible={leadVisible}
        setVisible={setLeadVisible}
        refreshObjects={[setInfos]}
        handleData={handleData}
        actions={{ add: addLeadMiss }}
        setParams={setParams}
      >
        <div className="grid">
          <div
            className="w-full flex wrap-3 px-2"
            style={{ alignItems: "center" }}
          >
            <div className="mb-3 px-2 change-disabled">
              <div className="w-full mb-2 flex justify-content-between">
                <label htmlFor="password" className="font-medium w-full">
                  Pass
                </label>
              </div>
              <Password
                inputId="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <span
              style={{
                backgroundColor: "#33a1a5",
                borderRadius: "5px",
                color: "white",
              }}
              onClick={handleCheck}
              className="check_role shadow-4 p-2 mt-2 cursor-pointer"
            >
              Check role
            </span>
          </div>
          <div className="col-6">
            <DropdownForm
              value={infos?.project_id_ad}
              onChange={(e) =>
                setInfos({
                  ...infos,
                  project_id_ad: e.target.value ? e.target.value : "",
                })
              }
              options={list_page}
              optionLabel={(option) =>
                `${option.page_name}  (${option.page_id})`
              }
              optionValue="page_id"
              label="Page (*)"
              disabled={disabled}
            />
          </div>
          <div className="col-6">
            <CalendarForm
              label="Thời gian"
              value={infos.date}
              onChange={(e) => {
                const selectedDate = e.target.value;
                const formattedDate = new Date(selectedDate).toLocaleDateString(
                  "en-US",
                  {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric",
                  }
                );
                setInfos({ ...infos, date: formattedDate });
              }}
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ height: "25vh" }}></div>
      </FormUpdateDialog>
    </>
  );
};
export default AddMiss;
